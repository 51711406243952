<template>
	<div class="journal-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !jeNo"
		         v-on:ok="deleteJournal"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Journal</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalJeNo" class="font-weight-bold">Journal No.</label>
					<input type="text" id="modalJeNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': jeNoInvalid}"
					       v-model="jeNo"
					       v-on:input="jeNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-100 h-100 rounded bg-white d-flex flex-column align-items-center overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveJournal"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!journal"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="jeNo" class="font-weight-bold">Journal No.</label>
					<input type="text" id="jeNo" class="form-control position-relative" placeholder=""
					       v-bind:value="localJournal.jeNo" readonly>
				</div>
				<div class="date-picker-form-group form-group position-relative w-100">
					<label for="date" class="font-weight-bold text-truncate">Date of Transaction</label>
					<b-form-datepicker id="date" placeholder="Date"
					                   class="date-picker"
					                   v-bind:class="invalidInputs.date"
					                   v-bind:value="localJournal.date.split('T')[0]"
					                   v-on:input="updateDate($event)">
					</b-form-datepicker>
				</div>
				<div class="field-name-row position-relative w-100 d-flex">
					<h5 class="position-relative w-50 font-weight-bold text-center">Account</h5>
					<h5 class="debit position-relative ml-2 font-weight-bold text-center">Debit</h5>
					<h5 class="credit position-relative ml-2 font-weight-bold text-center">Credit</h5>
					<div class="icon-reserve ml-2"></div>
				</div>
				<div class="debit-entry position-relative w-100 d-flex" v-for="(entry, index) in localJournal.debits">
					<div class="form-group position-relative w-50">
						<label v-bind:for="`debitAccount${index}`" v-show="false"></label>
						<input type="text" v-bind:id="`debitAccount${index}`" placeholder="Account" class="form-control position-relative"
						       v-bind:class="debitsInvalid[index].account"
						       v-bind:value="entry.account"
						       v-on:input="updateEntry('debits', 'account', $event.target.value, index)">
					</div>
					<div class="form-group debit position-relative ml-2">
						<label v-bind:for="`debit${index}`" v-show="false"></label>
						<input type="text" v-bind:id="`debit${index}`" placeholder="" class="form-control position-relative"
						       v-bind:class="debitsInvalid[index].amount"
						       v-bind:value="entry.amount"
						       v-on:change="updateEntry('debits', 'amount', $event.target.value, index)">
					</div>
					<div class="form-group credit position-relative ml-2">
						<label v-bind:for="`creditDisabled${index}`" v-show="false"></label>
						<input type="text" v-bind:id="`creditDisabled${index}`" placeholder="" class="form-control position-relative"
						       value="" disabled>
					</div>
					<div class="entry-icons position-relative ml-2 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light rounded-circle p-0 flex-shrink-0"
						        v-bind:disabled="localJournal.debits.length >= 50"
						        v-on:click="addDeleteEntry('debits', 'add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
						<button type="button" class="button btn btn-custom-light rounded-circle p-0 ml-3 flex-shrink-0"
						        v-bind:disabled="localJournal.debits.length === 1"
						        v-on:click="addDeleteEntry('debits', 'delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
					</div>
				</div>
				<div class="credit-entry position-relative w-100 d-flex" v-for="(entry, index) in localJournal.credits">
					<div class="form-group position-relative w-50">
						<label v-bind:for="`creditAccount${index}`" v-show="false"></label>
						<input type="text" v-bind:id="`creditAccount${index}`" placeholder="Account" class="form-control position-relative"
						       v-bind:class="creditsInvalid[index].account"
						       v-bind:value="entry.account"
						       v-on:input="updateEntry('credits', 'account', $event.target.value, index)">
					</div>
					<div class="form-group debit position-relative ml-2">
						<label v-bind:for="`debitDisabled${index}`" v-show="false"></label>
						<input type="text" v-bind:id="`debitDisabled${index}`" placeholder="" class="form-control position-relative"
						      value="" disabled>
					</div>
					<div class="form-group credit position-relative ml-2">
						<label v-bind:for="`credit${index}`" v-show="false"></label>
						<input type="text" v-bind:id="`credit${index}`" placeholder="" class="form-control position-relative"
						       v-bind:class="creditsInvalid[index].amount"
						       v-bind:value="entry.amount"
						       v-on:change="updateEntry('credits', 'amount', $event.target.value, index)">
					</div>
					<div class="entry-icons position-relative ml-2 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-info rounded-circle p-0 flex-shrink-0"
						        v-bind:disabled="localJournal.credits.length >= 50"
						        v-on:click="addDeleteEntry('credits', 'add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
						<button type="button" class="button btn btn-info rounded-circle p-0 ml-3 flex-shrink-0"
						        v-bind:disabled="localJournal.credits.length === 1"
						        v-on:click="addDeleteEntry('credits', 'delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
					</div>
				</div>
				<div class="field-name-row position-relative w-100 d-flex mb-3">
					<h5 class="position-relative w-50 font-weight-bold text-right">Total</h5>
					<h5 class="debit position-relative ml-2 font-weight-bold text-center"
					    v-bind:class="invalidInputs.totalDebits">{{totalDebits}}</h5>
					<h5 class="credit position-relative ml-2 font-weight-bold text-center"
					    v-bind:class="invalidInputs.totalCredits">{{totalCredits}}</h5>
					<div class="icon-reserve ml-2"></div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="descriptions" class="font-weight-bold">Descriptions</label>
					<textarea id="descriptions" rows="3" class="form-control position-relative"
					          v-model="localJournal.descriptions"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localJournal.notes"></textarea>
				</div>
				
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Journal Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Journal Not Yet Saved"
					       v-bind:value="localJournal.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localJournal.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localJournal.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Journal Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Journal Not Yet Created"
					       v-bind:value="localJournal.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localJournal.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localJournal.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent  } from "@/utility/helpers";

export default {
	name: "JournalDetails",
	
	components: {
		FormControls,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		journalId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initJournal();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localJournal: {
				_id: "",
				jeNo: "",
				debits: [
					{
						account: "",
						amount: "",
					},
				],
				credits: [
					{
						account: "",
						amount: "",
					},
				],
				date: "",
				sum: "",
				descriptions: "",
				createdAt: "",
				updatedAt: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				createdByName: "",
				updatedByName: "",
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				date: "",
				totalDebits: "",
				totalCredits: "",
			},
			debitsInvalid: [
				{
					account: "",
					amount: "",
				},
			],
			creditsInvalid: [
				{
					account: "",
					amount: "",
				},
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			jeNo: "",
			jeNoInvalid: false,
		}
	},
	
	computed: {
		journal() {
			return this.$store.state.contents.journalEntries.find(({ _id }) => _id === this.journalId);
		},
		
		savable() {
			// fall through pattern
			if (!this.journal) return true;
			// can safely access properties inside this.journal due to the first if clause in this function
			if (this.entriesDifferent(this.localJournal.debits, this.journal.debits)) return true;
			if (this.entriesDifferent(this.localJournal.credits, this.journal.credits)) return true;
			// though we need not care about time zone for date but using dateTimeDifferent for this check is fine
			// as long as we update the date string properly: time portion must be all 0s
			if (dateTimeDifferent(this.journal.date, this.localJournal.date)) return true;
			// need not check sum since sum is calculated based on debits and credits
			const keys = ["descriptions", "notes"];
			return keys.some(key => this.localJournal[key] !== this.journal[key]);
		},
		
		// changing the amount in debits or credits will trigger vue reactivity
		totalDebits() {
			return this.localJournal.debits.reduce((acc, { amount }) => {
				const parsedAmount = parseFloat(amount);
				if (isNumeric(amount) && parsedAmount >= 0) return acc + parsedAmount;
				return acc;
			}, 0).toFixed(2);
		},
		
		totalCredits() {
			return this.localJournal.credits.reduce((acc, { amount }) => {
				const parsedAmount = parseFloat(amount);
				if (isNumeric(amount) && parsedAmount >= 0) return acc + parsedAmount;
				return acc;
			}, 0).toFixed(2);
		}
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.jeNo = "";
				this.jeNoInvalid = false;
			}
		},
		
		entriesDifferent(arr1, arr2) {
			if (arr1.length === arr2.length) return arr1.some(entry =>
				// if arr2 contains an exact match, return false to keep checking the next item
				!arr2.some(({ account, amount }) =>
					entry.account === account && entry.amount === amount
				)
			)
			return true;
		},
		
		initJournal() {
			if (this.journal) {
				const obj = {...this.journal};
				obj.debits = [];
				obj.credits = [];
				this.debitsInvalid = [];
				this.creditsInvalid = [];
				this.journal.debits.forEach(debit => {
					obj.debits.push({...debit});
					this.debitsInvalid.push({ account: "", amount: "" });
				});
				this.journal.credits.forEach(credit => {
					obj.credits.push({...credit});
					this.creditsInvalid.push({ account: "", amount: "" });
				});
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// createdAt and updatedAt must present in the database, need no nullish check
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				this.localJournal = obj;
			} else {
				this.localJournal._id = uuidv4();
				this.localJournal.jeNo = "JE-" + this.localJournal._id.split("-")[0].toUpperCase();
			}
		},
		
		addDeleteEntry(type, option, index) {
			if (option === "add") {
				this.localJournal[type].splice(index + 1, 0, { account: "", amount: "" });
				this[`${type}Invalid`].splice(index + 1, 0, { account: "", amount: "" });
			} else {
				this.localJournal[type].splice(index, 1);
				this[`${type}Invalid`].splice(index, 1);
			}
		},
		
		updateDate(value) {
			this.localJournal.date = `${value}T00:00:00.000Z`;
			this.invalidInputs.date = "";
		},
		
		updateEntry(type, key, value, index) {
			this.localJournal[type][index][key] = value;
			switch (key) {
				// bind to change event instead of input event
				case "amount":
					this.invalidInputs.totalDebits = "";
					this.invalidInputs.totalCredits = "";
					const parsedValue = parseFloat(value);
					if (isNumeric(value) && parsedValue >= 0) {
						this.localJournal[type][index][key] = parsedValue.toFixed(2);
						this[`${type}Invalid`][index][key] = "";
					} else {
						this[`${type}Invalid`][index][key] = "invalid";
					}
					break;
				default:
					this[`${type}Invalid`][index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
		},
		
		validateTotal() {
			if (this.totalDebits !== this.totalCredits) {
				this.invalidInputs.totalDebits = "invalid";
				this.invalidInputs.totalCredits = "invalid";
			}
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const keys = ["date"];
			const entryKeys = ["account", "amount"];
			this.validateTotal();
			keys.forEach(key => {
				if (!this.localJournal[key] ||
					this.localJournal[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.debitsInvalid.forEach((entry, index) => entryKeys.forEach(key => {
				if (!this.localJournal.debits[index][key] ||
					this.localJournal.debits[index][key].trim().length === 0) entry[key] = "invalid"; //object reference
			}));
			this.creditsInvalid.forEach((entry, index) => entryKeys.forEach(key => {
				if (!this.localJournal.credits[index][key] ||
					this.localJournal.credits[index][key].trim().length === 0) entry[key] = "invalid"; //object reference
			}));
			return Object.keys(this.invalidInputs).some(key => this.invalidInputs[key] === "invalid") ||
				this.debitsInvalid.some(entry => entryKeys.some(key => entry[key] === "invalid")) ||
				this.creditsInvalid.some(entry => entryKeys.some(key => entry[key] === "invalid"));
		},
		
		async saveJournal() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["createdByName", "updatedByName", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const journal = {...this.localJournal};
				if (!journal.createdAt) {
					journal.createdAt = now;
					journal.createdBy = this.$store.state.user.user._id;
				}
				journal.updatedAt = now;
				journal.updatedBy = this.$store.state.user.user._id;
				journal.sum = this.totalDebits;
				deleteKeys.forEach(key => delete journal[key]);
				await this.$store.dispatch("contents/updateContent", { key: "journalEntries", value: journal});
				if (this.journalId) {
					this.initJournal();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/accounting/journal/${journal._id}`);
					// need to run initJournal after route change since journalId prop was undefined
					this.initJournal();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Journal Created!";
				}
			}
		},
		
		async deleteJournal(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.jeNo !== this.journal.jeNo) this.jeNoInvalid = true;
			} else if (this.jeNo !== this.journal.jeNo) {
				bvModalEvent.preventDefault();
				this.jeNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "journalEntries", _id: this.journal._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/accounting/journal");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/accounting/journal");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
.debit, .credit {
	width: 20%;
	
	&.invalid {
		color: $color-error;
	}
}

.icon-reserve {
	width: 10%;
	min-width: 64px;
}

.entry-icons {
	width: 10%;
	height: 38px;
	min-width: 64px;
	
	.button {
		width: 22px;
		height: 22px;
	}
}
</style>